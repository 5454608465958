.markdown-body {
    background: #fff;
}

.content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div#root {
    background-color: #d2d2d2;
    background-image: url(/static/media/pattern_2.8dd827eb.png);
}

a {
    opacity: 0.7;
}

a:hover {
    opacity: 1;
}
.container-column {
    display: flex;
    flex-direction: column
}

.container-space {
    display: flex;
    justify-content: space-around;
    width: 250px;
    margin: 0 auto;
}

.plural-container {
    text-align: center;
    padding: 20px;
}
.container-column {
    display: flex;
    flex-direction: column
}
.company-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.container-column {
    display: flex;
    flex-direction: column
}
.project-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.container-footer {
    background-color: #f6f8fa;
}
.content-footer {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-footer {
    flex: 1 1;
    padding: 30px;
}

.link-footer {
    color: #0366d6;
    text-decoration: none;
}
