.container-column {
    display: flex;
    flex-direction: column
}

.container-space {
    display: flex;
    justify-content: space-around;
    width: 250px;
    margin: 0 auto;
}

.plural-container {
    text-align: center;
    padding: 20px;
}