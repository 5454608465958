.markdown-body {
    background: #fff;
}

.content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div#root {
    background-color: #d2d2d2;
    background-image: url(assets/pattern_2.png);
}

a {
    opacity: 0.7;
}

a:hover {
    opacity: 1;
}