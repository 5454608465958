.container-footer {
    background-color: #f6f8fa;
}
.content-footer {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-footer {
    flex: 1;
    padding: 30px;
}

.link-footer {
    color: #0366d6;
    text-decoration: none;
}